import {
  createTheme,
  responsiveFontSizes,
  Theme,
} from "@material-ui/core/styles";
import { CSSProperties } from "react";
import { containerMaxWidth } from "../../helpers/clientSide/constants";
import { colorsFromXd } from "./colorsFromXd";

// Container spacing
const sectionSpacing = (theme: Theme): CSSProperties => ({
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(8),
  },
  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(12.5),
  },
});

// Caveat style
const caveat = {
  fontFamily: "caveat",
  transform: "matrix(1, -0.05, 0.05, 1, 0, 0)",
};

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: colorsFromXd.lightGreen,
      dark: colorsFromXd.black,
      contrastText: colorsFromXd.fluorescentGreen,
    },
    secondary: {
      main: colorsFromXd.darkGreen,
      light: colorsFromXd.white,
      dark: colorsFromXd.turquoise,
      contrastText: colorsFromXd.fluorescentGreen,
    },
    background: {
      default: colorsFromXd.white,
    },
    grey: {
      100: colorsFromXd.greyishGreen,
      200: colorsFromXd.greyShadow,
      300: colorsFromXd.darkGrey,
    },
  },
  typography: {
    fontFamily: "dm-sans, sans-serif",
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "2.5rem", // 40px
      marginBottom: "1.85rem", // 29.6px
      letterSpacing: "0.05rem", // 0.8px
    },
    h2: {
      fontSize: "1.875rem", // 30px
      marginBottom: "1.663rem", // 26.6px
      letterSpacing: "0.05rem", // 0.8px
    },
    h3: {
      fontSize: "1.5rem", // 24px
      letterSpacing: "0.05rem", // 0.8px
    },
    h4: {
      fontSize: "0.688rem", // 11px
      fontFamily: "dm-sans, sans-serif",
      textTransform: "uppercase",
      writingMode: "vertical-lr",
      transform: "rotate(180deg)",
      letterSpacing: "0.2rem",
    },
    h5: {
      fontSize: "2.5rem", // 40px
      textAlign: "left",
      marginBottom: "6rem",
      lineHeight: 1,
      ...caveat,
    },
    body1: {
      fontSize: "1.1rem !important", // 17.6px
      textAlign: "left",
      letterSpacing: "0.025rem", // 0.4px
    },
    body2: {
      fontSize: "0.9rem", // 14.4px
      lineHeight: "1.5rem", // 24px
    },
    subtitle1: {
      fontSize: "2.5rem", // 40px
      ...caveat,
      letterSpacing: "0.03rem", // 0.5px
    },
    subtitle2: {
      fontSize: "1.875rem", // 30px
      ...caveat,
      letterSpacing: "0.03rem", // 0.5px
      lineHeight: 1,
    },
    caption: {
      fontFamily: "caveat",
      color: colorsFromXd.fluorescentGreen,
    },
    allVariants: {
      color: colorsFromXd.black,
    },
  },
  spacing: (factor) => `${1 * factor}rem`,
});

const themeWithOverrides: Theme = {
  ...theme,
  overrides: {
    MuiContainer: {
      root: {
        position: "relative",
        ...sectionSpacing(theme),
        [theme.breakpoints.up("lg")]: {
          maxWidth: `${containerMaxWidth}px !important`,
        },
        "&.noMargin": {
          marginTop: 0,
        },
        "&.textCenter": {
          textAlign: "center",
        },
        "&.marginBottom": {
          [theme.breakpoints.down("sm")]: {
            paddingBottom: theme.spacing(6),
          },
          [theme.breakpoints.up("md")]: {
            paddingBottom: theme.spacing(12.5),
          },
        },
        "&.paddingX": {
          [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
          },
        },
      },
    },

    MuiTypography: {
      colorTextPrimary: {
        color: colorsFromXd.black,
      },
      colorTextSecondary: {
        color: colorsFromXd.white,
      },
      subtitle1: {
        color: theme.palette.primary.contrastText,
      },
      subtitle2: {
        color: theme.palette.primary.contrastText,
      },
      h3: {
        fontWeight: theme.typography.fontWeightBold,
      },
      h4: {
        color: theme.palette.secondary.dark,
      },
    },
    MuiCard: {
      root: {
        borderRadius: "1.5em",
        padding: theme.spacing(2),
        boxShadow: "none",
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
        "&:last-child": {
          paddingBottom: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: "3em",
        padding: 0,
      },
    },
    MuiFab: {
      root: {
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
        [theme.breakpoints.down("sm")]: {
          width: theme.spacing(3.5),
          height: theme.spacing(3.5),
        },
      },
      primary: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        "&:hover": {
          backgroundColor: theme.palette.secondary.light,
        },
      },
    },
    MuiDialog: {
      paper: {
        minHeight: "60%",
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        overflowY: "visible",
        "&:first-child": {
          paddingTop: 0,
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: theme.spacing(0.5),
      },
    },
    MuiButton: {
      root: {
        padding: 0,
        minWidth: 0,
      },
    },
  },
};

const themeWithResponsiveFontSizes = responsiveFontSizes(themeWithOverrides, {
  factor: 3,
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
});

export default themeWithResponsiveFontSizes;
