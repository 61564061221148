/**
 * These are the colors as they are defined in the xd files.
 *
 * Some of them are left out though, because they are almost identical to another one.
 */

export const colorsFromXd = {
  white: "#FFFFFF",
  black: "#000000",
  greyishGreen: "#F0F4F4",
  greyShadow: "#F7F9F9",
  darkGrey: "#ABABAB",
  darkGreen: "#003D42",
  lightGreen: "#F1FBF9",
  fluorescentGreen: "#4AEACB",
  turquoise: "#0094A1",
};
