import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { useSiteInfoQuery } from "../../queries/siteInfoQuery";
import theme from "../theme/theme";

interface PageWrapperProps {
  children: ReactNode;
}

const PageWrapper: React.FunctionComponent<PageWrapperProps> = (
  props: PageWrapperProps
) => {
  const siteInfo = useSiteInfoQuery();

  // This is somehow necessary because of our strict mode and the maybe type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const seo = siteInfo.wp as any;

  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Helmet>

      <ThemeProvider theme={theme}>
        <SEOContext.Provider value={{ global: seo }}>
          <CssBaseline />

          {props.children}
        </SEOContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default PageWrapper;
